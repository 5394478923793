import React, { useEffect } from 'react'
import { EnvelopeIcon } from '@heroicons/react/24/outline'

// navigation
import { Link } from 'gatsby'
import { CONTACT } from '@constants/routes'

// context
import { PlansContextProvider } from '@context/plans'
import { PaddleContextProvider } from '@context/paddle'

// Layout
import MailerLiteEmailForm from '@components/Layout/MailerLiteEmailForm'

export default function Layout({ children, location }) {
    const { pathname } = location
    const excludeMailerlite = ['signup', 'thanks', 'contact']
    const excludeContact = [...excludeMailerlite, 'podcast', 'blog']

    useEffect(() => {
        setTimeout(() => {
            window.scroll({
                top: -1,
                left: 0,
                behavior: 'smooth',
            })
        }, 1)
    }, [location])

    return (
        <PaddleContextProvider>
            <PlansContextProvider>{children}</PlansContextProvider>
            {!excludeContact.some((path) => pathname.includes(path)) && (
                <Link
                    to={CONTACT}
                    className='fixed bottom-5 right-5 z-50 hidden rounded-full rounded-br-none bg-haze ring-2 ring-white xl:block'
                    aria-label='Ponte en contacto'
                    title='Ponte en contacto'
                >
                    <span className='absolute right-0.5 top-0.5 inline-flex size-3 rounded-full bg-eastern' />
                    <span className='absolute right-0.5 top-0.5 inline-flex size-3 animate-ping rounded-full bg-eastern opacity-75' />
                    <EnvelopeIcon className='m-3 size-7 stroke-white p-0.5' />
                </Link>
            )}
            {!excludeMailerlite.some((path) => pathname.includes(path)) && <MailerLiteEmailForm location={location} />}
        </PaddleContextProvider>
    )
}
