import React from 'react'
import '@assets/css/main.css'
import Layout from '@components/Layout'

//
// The {...props} provide same data to Layout as Page element including location, data, etc
// Therefore we don't explicitly pass it
export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>
}
