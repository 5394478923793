import React, { createContext, useContext, useState } from 'react'

// hooks
import useScript from '@hooks/useScript'

// Create the context with a default value of null
const PaddleContext = createContext(undefined)

// Hook to use it all over the app
export const usePaddleCtx = () => {
    return useContext(PaddleContext)
}

export const PaddleContextProvider = ({ children }) => {
    const [paddle, setPaddle] = useState(null)

    useScript('https://cdn.paddle.com/paddle/v2/paddle.js', {
        onLoad: () => {
            if (process.env.GATSBY_PADDLE_ENV === 'sandbox') {
                window.Paddle.Environment.set('sandbox')
            }

            window.Paddle.Initialize({
                token: process.env.GATSBY_PADDLE_CLIENT_SIDE_TOKEN,
                checkout: {
                    settings: {
                        locale: 'es',
                        displayMode: 'inline',
                        frameTarget: 'paddle-checkout',
                        frameInitialHeight: 450,
                        frameStyle: 'width: 100%; min-width: 312px; background-color: transparent; border: none;',
                        allowLogout: true,
                    },
                },
            })

            setPaddle(window.Paddle)
        },
    })

    return <PaddleContext.Provider value={{ Paddle: paddle }}>{children}</PaddleContext.Provider>
}
