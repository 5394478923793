import React, { createContext, useContext, useEffect, useState } from 'react'

// Create the context with a default value of null
const PlansContext = createContext(null)

// Hook to use it all over the app
export const usePlansCtx = () => {
    return useContext(PlansContext)
}

export const PlansContextProvider = ({ children }) => {
    const [plans, setPlans] = useState([])
    const [newCustomer, setNewCustomer] = useState(null)

    useEffect(() => {
        const getPlans = async () => {
            const url = process.env.GATSBY_TRIBU_API_URL + '/paddle/billing/plans/data'
            const request = await fetch(url, {
                method: 'GET',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
            })

            const response = await request.json()
            setPlans(response)
        }

        getPlans().then(() => null)
    }, [])

    return (
        <PlansContext.Provider value={{ plans, setPlans, newCustomer, setNewCustomer }}>
            {children}
        </PlansContext.Provider>
    )
}
