import { useEffect } from 'react'

export default function useScript(src, { onLoad, onError } = {}) {
    useEffect(() => {
        let script = document.querySelector(`script[src="${src}"]`)

        if (!script) {
            // Script doesn't exist, create and load it
            script = document.createElement('script')
            script.src = src
            script.async = true

            // Attach handlers
            if (onLoad) {
                script.onload = () => onLoad()
            }

            if (onError) {
                script.onerror = () => onError()
            }

            // Append to DOM
            document.body.appendChild(script)
        } else {
            // Script exists, check if it's already loaded
            if (script.readyState === 'complete' || script.readyState === 'loaded') {
                onLoad?.() // Call onLoad immediately if script is already loaded
            }
        }

        // No cleanup is needed to keep the script in the DOM
    }, [src, onLoad, onError])
}
